<template>
  <div>
    <el-switch
  v-model="checkedS"
  @change='onChange' :disabled='isStatus'
  active-color="#66b1ff"
  inactive-color="#DCDFE6">
</el-switch>
    <!-- <a-switch :checked ='this.checkedS' @change='onChange' :disabled='isStatus'/> -->
  </div>
</template>

<script>
  import fetchData from "@/config/fetch";
  
  export default {
  name: 'ListStatus',
  props:["checkedStatus","StatusId",'token'],
  data () {
    return {
      checkedS: this.checkedStatus,
      isStatus: false
    }
  },
  methods:{
    onChange(checked){
       this.checkedS = checked
       this.isStatus = true
          fetchData(window.Glod['ccex-emailsync']+"/jwtchangesyncmailstatus",
          {"token":this.token,id:this.StatusId,status: checked?1:0},
          function(res){
            let data = eval(res);
            if(data.code == 200){
              this.isStatus = false
              // 获取同步邮箱列表 
              fetchData(window.Glod['ccex-emailsync']+"/jwtgetsyncmails",
                {"token":this.token,"cctype":"userid"},
                function(res){
                  let data = eval(res);
                  if(data.code == 200){
                    this.emailList = data.jwtsyncmails;
                  }
                }.bind(this)
              )
            }
          }.bind(this)
			)
    }
  },
  mounted(){

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  
</style>
