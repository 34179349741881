<!--
 * @Author: chenwc
 * @Date: 2021-09-13 17:48:12
 * @LastEditTime: 2022-05-06 14:08:55
 * @LastEditors: Please set LastEditors
 * @Description: 改用element组件
 * @FilePath: /linghtning-r99/src\views\Email\personalEmailBox.vue
-->
<template>
  <div class="cloudcc_email">
    <!-- 顶部关联邮箱简介 -->
    <div class="cloudcc_title">
      <svg class="title_img" aria-hidden="true">
        <use href="#icon-email1"></use>
      </svg>
      <div>
        <!-- 关联邮箱账号 -->
        <h3 class="h3Class">{{ $t("label_emailsync_connectaccount") }}</h3>
        <!-- 将您企业的邮箱，如sales@company.com，info@company.com，support@company.com添加于此，
          系统将自动根据所收邮件生成潜在客户或个案记录，确保及时跟进来信客户 -->
        <p>
          {{ $t("label_emailsync_connectaccount_description") }}
        </p>
      </div>
    </div>
    <div class="email_list">
      <!-- 无数据的样式 -->
      <ul class="init_email" v-if="!emailListlen">
        <li>
          <div
            class="init_email_icon"
            v-show="country !== 'china'"
            @click="addGmail"
          >
            <svg class="img" aria-hidden="true">
              <use href="#icon-gmail"></use>
            </svg>
            <span>Gmail</span>
          </div>
          <div
            class="init_email_icon"
            v-show="country == 'china'"
            @click="chinaAddEmail"
          >
            <svg class="img" aria-hidden="true">
              <use href="#icon-gmail"></use>
            </svg>
            <span>Gmail</span>
          </div>
        </li>
        <li>
          <div class="init_email_icon" @click="addOutlook">
            <svg class="img" aria-hidden="true">
              <use href="#icon-office"></use>
            </svg>
            <span>Office 365</span>
          </div>
        </li>
        <li>
          <div class="init_email_icon" @click="addOutlook">
            <svg class="img" aria-hidden="true">
              <use href="#icon-outlook"></use>
            </svg>
            <span>Outlook.com</span>
          </div>
        </li>
        <li>
          <div class="init_email_icon" @click="showPanel">
            <svg class="img" aria-hidden="true">
              <use href="#icon-other"></use>
            </svg>
            <span>Other</span>
          </div>
        </li>
      </ul>
      <!-- 有数据的时候列表展示 -->
      <div
        v-loading="listLoading"
        class="loading_List"
        :element-loading-text="tip"
      ></div>
      <div v-show="emailListlen && !listLoading">
        <!-- 邮箱列表 -->
        <div class="email_list_content">
          <table>
            <thead>
              <tr>
                <!-- 操作 -->
                <th>
                  <div class="email_list_th">
                    <span></span
                    ><span>{{ $t("label_emailtocloudcc_filed_action") }}</span>
                  </div>
                </th>
                <!-- 邮箱 -->
                <th>
                  <div class="email_list_th">
                    <span></span
                    ><span>{{ $t("label_emailtocloudcc_filed_email") }}</span>
                  </div>
                </th>
                <!-- 关联时间 -->
                <th>
                  <div class="email_list_th">
                    <span></span
                    ><span>{{ $t("label_emailsync_form_connecttime") }}</span>
                  </div>
                </th>
                <!-- 启用状态 -->
                <th>
                  <div class="email_list_th">
                    <span></span
                    ><span>{{ $t("label_emailsync_form_stutas") }}</span>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody v-if="emailList[0]">
              <tr v-for="(item, index) in emailList" :key="index">
                <!-- 取消关联 -->
                <td class="edit_td">
                  <el-button
                    type="text"
                    class="delete_btn"
                    @click="showModal(item)"
                  >
                    {{ $t("label_emailsync_form_disconnect") }}
                  </el-button>
                </td>
                <td>{{ item.syncmail }}</td>
                <td>
                  {{ getLocalTime(item.createtime, timezone, countrycode) }}
                </td>
                <td>
                  <ListStatus
                    :checkedStatus="item.status == 1 ? true : false"
                    :StatusId="item.id"
                    :token="token"
                  ></ListStatus>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- $t('lable.product.page.previous'):返回上一页 -->
        <!-- <div class="btn_box"><a :href="rtnURL">{{$t('lable.product.page.previous')}}</a></div> -->
        <!-- 删除提示 title="提示" -->
        <el-dialog
          :title="this.disconnect"
          :visible.sync="visible"
          width="440px"
        >
          <!-- 取消关联将会清空该邮箱中所有未关联相关项的邮件，是否确认取消关联 -->
          <p>
            {{ $t("front-personalEmailBox-module-v12-clear-not-associated") }}
          </p>
          <!-- 取消关联后，将无法使用该邮箱收发邮件，确认取消？ -->
          <!-- <p>{{$t('label.email.cancel')}}</p> -->
          <!-- 自定义底部按钮 -->
          <div slot="footer">
            <!-- 取消关联 “取消”按钮 -->
            <el-button @click="cancelbutton">{{
              $t("label_emailsync_button_cancel")
            }}</el-button>
            <!-- 只清空未关联相关项的邮件 -->
            <!-- <el-button type='primary' @click="handleDelete('1')">{{$t('label.email.clearItem')}}</el-button> -->
            <!-- 清空所有 -->
            <!-- <el-button type='primary' @click="handleDelete('0')">{{$t('label.email.clearAll')}}</el-button> -->
            <!-- 确认 -->
            <el-button type="primary" @click="handleDelete('1')">{{
              $t("label.weixin.confirm")
            }}</el-button>
          </div>
        </el-dialog>
      </div>
      <!-- 其他邮箱 -->
      <div class="email_Other" v-show="panelShow">
        <div class="email_Other_box">
          <div class="email_other_close" @click="hidePanel">
            <svg class="closeSvg" aria-hidden="true">
              <use href="#icon-a-Group90"></use>
            </svg>
          </div>
          <div class="email_other_logo">
            <svg class="img3" aria-hidden="true">
              <use href="#icon-logo_left_top"></use>
            </svg>
          </div>
          <!-- <h3 class="email_other_H3">请选择您的邮箱供应商:</h3> -->
          <!-- <p class="error_add">{{addEmail}}</p> -->
          <div class="email_other_login">
            <el-form
              id="components-form-demo-normal-login"
              :model="form"
              :rules="rules"
              ref="form"
              label-position="top"
              class="login-form"
            >
              <!-- 账号 -->
              <el-form-item
                :label="$t('label_emailsync_form_emailaddress')"
                prop="emailValue"
              >
                <el-input v-model="form.emailValue"></el-input>
              </el-form-item>
              <!-- 密码 -->
              <el-form-item
                :label="$t('label_emailsync_form_emialpassword')"
                prop="passWordValue"
              >
                <el-input v-model="form.passWordValue" show-password></el-input>
              </el-form-item>

              <!-- 设置： -->
              <!-- <h3 class="email_other_Set">{{$t('label_emailsync_form_seversetting')}}</h3> -->
              <div class="email_other_host">
                <div class="email_other_box">
                  <el-form-item label="IMAP:" prop="imapValue">
                    <el-input v-model="form.imapValue"></el-input>
                  </el-form-item>
                </div>
                <div class="email_other_box" style="width: 26%">
                  <el-form-item
                    :label="$t('label_emailtocloudcc_filed_port')"
                    prop="imapNumValue"
                  >
                    <el-input v-model="form.imapNumValue"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="email_other_host">
                <div class="email_other_box">
                  <el-form-item label="SMTP:" prop="smtpValue">
                    <el-input v-model="form.smtpValue"></el-input>
                  </el-form-item>
                </div>
                <div class="email_other_box" style="width: 26%">
                  <el-form-item
                    :label="$t('label_emailtocloudcc_filed_port')"
                    prop="smtpNumValue"
                  >
                    <el-input v-model="form.smtpNumValue"></el-input>
                  </el-form-item>
                </div>
              </div>
              <el-form-item>
                <!-- 完成 -->
                <el-button
                  type="primary"
                  @click="handleSubmit"
                  class="login-form-button"
                >
                  {{ $t("label_emailtocloudcc_button_complete") }}
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <!-- 错误提示弹窗 -->
      <div class="errorBox" v-show="isOk">
        <div class="error">
          <div class="error_title">
            <i class="el-icon-warning-outline" style="color: red"></i>
            <span>{{ this.errorTitle }}</span>
          </div>
          <!-- 确认 -->
          <button @click="confirmbtn">
            {{ $t("label_emailtocloudcc_button_sure") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fetchData from "@/config/fetch";
import ListStatus from "./ListStatus";
import { deleteEmail } from "./api.js";

function add0(m) {
  return m < 10 ? "0" + m : m;
}
export default {
  name: "personalEmailBo",
  // 引入的组件
  components: {
    ListStatus: ListStatus,
  },
  data() {
    // 初始化数据
    return {
      form: {
        emailValue: "", // 邮箱号
        passWordValue: "", // 邮箱密码
        imapValue: "", // imap地址
        imapNumValue: "993", // imap编号
        smtpValue: "", // smtp地址
        smtpNumValue: "465", // smtp编号
      }, // 其他邮箱
      rules: {
        emailValue: [
          {
            required: true,
            message:
              this.$i18n.t("lable.product.please.enter") +
              this.$i18n.t("label.department.email"),
            trigger: "blur",
          },
        ],
        passWordValue: [
          {
            required: true,
            message: this.$i18n.t("label.weixin.input.password"),
            trigger: "blur",
          },
        ],
        imapValue: [
          {
            required: true,
            message: this.$i18n.t("lable.product.please.enter") + "imap",
            trigger: "blur",
          },
        ],
        imapNumValue: [
          {
            required: true,
            message:
              this.$i18n.t("lable.product.please.enter") +
              this.$i18n.t("label.emailtocloudcc.filed.port"),
            trigger: "blur",
          },
        ],
        smtpValue: [
          {
            required: true,
            message: this.$i18n.t("lable.product.please.enter") + "smtp",
            trigger: "blur",
          },
        ],
        smtpNumValue: [
          {
            required: true,
            message:
              this.$i18n.t("lable.product.please.enter") +
              this.$i18n.t("label.emailtocloudcc.filed.port"),
            trigger: "blur",
          },
        ],
      }, // 验证规则
      timezone: "", // url传入的时区
      code: Number, // url传入的code
      countrycode: "", // 城市code
      token: "", // token
      returnurl: window.Glod.EMAIL_RETURN, // 返回路由
      lange: "", // 多语言字段
      groupid: "", // 组织id
      userid: "", // 用户id
      country: "china", // 国家
      disconnect: this.$i18n.t("label_emailsync_message"), // 取消关联“提示”
      error8: this.$i18n.t("label_emailtocloudcc_redmessage8"),
      jump: this.$i18n.t("labal_About_to_jump"), // 即将跳转
      visible: false, // 模态框
      emailList: [null], // 邮箱列表
      emailId: "", // 删除同步邮箱id
      emailItem: "", // 删除同步邮箱信息
      emailListlen: Number,
      StatusId: "", // 是否禁用邮箱的id
      isOk: false,
      errorTitle: "",
      ListOther: true,
      panelShow: false,
      listLoading: false,
      tip: "loading...",
      date: new Date().getTime(),
      formalUrl: "",
      loading: false,
      addEmail: "",
    };
  },
  methods: {
    // 时间显示
    formatDate(timeStamp) {
      let time = new Date(timeStamp),
        y = time.getFullYear(),
        m = time.getMonth() + 1,
        d = time.getDate(),
        h = time.getHours(),
        mm = time.getMinutes(),
        s = time.getSeconds();

      return (
        y +
        "-" +
        add0(m) +
        "-" +
        add0(d) +
        " " +
        add0(h) +
        ":" +
        add0(mm) +
        ":" +
        add0(s)
      );
    },
    // 多时区
    getLocalTime(date, timezone, country) {
      //参数i为时区值数字，比如北京为东八区则输进8,西5输入-5
      let i = 0;
      var d = new Date(date);

      //得到1970年一月一日到现在的秒数

      var len = d.getTime();

      //本地时间与GMT时间的时间偏移差

      var offset = d.getTimezoneOffset() * 60000;

      //得到现在的格林尼治时间

      var utcTime = len + offset;
      let time = "";
      let year = "";
      let m = "";
      let r = "";
      let h = "";
      let mm = "";
      let s = "";
      if (timezone == "Pacific/Kiritimati") {
        i = 14;
      } else if (timezone == "Pacific/Chatham") {
        // (GMT+13:45) 查萨姆夏令时 (Pacific/Chatham)
        i = 13.75;
      } else if (
        timezone == "Pacific/Auckland" ||
        timezone == "Pacific/Enderbury" ||
        timezone == "Pacific/Tongatapu"
      ) {
        i = 13;
      } else if (timezone == "Asia/Kamchatka" || timezone == "Pacific/Fiji") {
        i = 12;
      } else if (timezone == "Pacific/Norfolk") {
        // (GMT+11:30) 诺福克时间 (Pacific/Norfolk)
        i = 11.5;
      } else if (
        timezone == "Australia/Lord_Howe" ||
        timezone == "Australia/Sydney" ||
        timezone == "Pacific/Guadalcanal"
      ) {
        i = 11;
      } else if (timezone == "Australia/ Adelaide") {
        // (GMT+10:30) 中央夏令时（南澳大利亚） (Australia/Adelaide)
        i = 10.5;
      } else if (timezone == "Australia/Brisbane") {
        i = 10;
      } else if (timezone == "Australia/Darwin") {
        // (GMT+09:30) 中央标准时间（北领地） (Australia/Darwin)
        i = 9.5;
      } else if (timezone == "Asia/Seoul" || timezone == "Asia/Tokyo") {
        i = 9;
      } else if (
        timezone == "Asia/Hong_Kong" ||
        timezone == "Asia/Kuala_Lumpur" ||
        timezone == "Asia/Manila" ||
        timezone == "Asia/Shanghai" ||
        timezone == "Asia/Singapore" ||
        timezone == "Asia/Taipei" ||
        timezone == "Australia/Perth"
      ) {
        i = 8;
      } else if (
        timezone == "Asia/Bangkok" ||
        timezone == "Asia/Jakarta" ||
        timezone == "Asia/Saigon"
      ) {
        i = 7;
      } else if (timezone == "Asia/Rangoon") {
        // (GMT+06:30) 缅甸时间 (Asia/Rangoon)
        i = 6.5;
      } else if (timezone == "Asia/Dacca" || timezone == "Asia/Yekaterinburg") {
        i = 6;
      } else if (timezone == "Asia/Katmandu") {
        // (GMT+05:45) 尼泊尔时间 (Asia/Katmandu)
        i = 5.75;
      } else if (timezone == "Asia/Calcutta" || timezone == "Asia/Colombo") {
        // (GMT+05:30) 印度标准时间 (Asia/Calcutta)
        // (GMT+05:30) 印度标准时间 (Asia/Colombo)
        i = 5.5;
      } else if (timezone == "Asia/Karachi" || timezone == "Asia/Tashkent") {
        i = 5;
      } else if (timezone == "Asia/Kabul") {
        // (GMT+04:30) 阿富汗时间 (Asia/Kabul)
        i = 4.5;
      } else if (
        timezone == "Asia/Dubai" ||
        timezone == "Asia/Tbilisi" ||
        timezone == "Europe/Moscow"
      ) {
        i = 4;
      } else if (timezone == "Asia/Tehran") {
        // (GMT+03:30) 伊朗标准时间 (Asia/Tehran)
        i = 3.5;
      } else if (
        timezone == "Africa/Nairobi" ||
        timezone == "Asia/Baghdad" ||
        timezone == "Asia/Kuwait" ||
        timezone == "Asia/Riyadh" ||
        timezone == "Europe/Minsk"
      ) {
        i = 3;
      } else if (
        timezone == "Africa/Cairo" ||
        timezone == "Africa/Johannesburg" ||
        timezone == "Asia/Jerusalem" ||
        timezone == "Europe/Athens" ||
        timezone == "Europe/Bucharest" ||
        timezone == "Europe/Helsinki" ||
        timezone == "Europe/Istanbul"
      ) {
        i = 2;
      } else if (
        timezone == "Africa/Algiers" ||
        timezone == "Europe/Amsterdam" ||
        timezone == "Europe/Berlin" ||
        timezone == "Europe/Brussels" ||
        timezone == "Europe/Paris" ||
        timezone == "Europe/Prague" ||
        timezone == "Europe/Rome"
      ) {
        i = 1;
      } else if (
        timezone == "Europe/Dublin" ||
        timezone == "Europe/Lisbon" ||
        timezone == "Europe/London" ||
        timezone == "GMT"
      ) {
        i = 0;
      } else if (timezone == "Atlantic/Cape_Verde") {
        i = -1;
      } else if (timezone == "Atlantic/South_Georgia") {
        i = -2;
      } else if (timezone == "America/St_Johns") {
        // (GMT-02:30) 纽芬兰夏令时 (America/St_Johns)
        i = -2.5;
      } else if (
        timezone == "America/Buenos_Aires" ||
        timezone == "America/Halifax" ||
        timezone == "America/Sao_Paulo" ||
        timezone == "Atlantic/Bermuda"
      ) {
        i = -3;
      } else if (
        timezone == "America/Indianapolis" ||
        timezone == "America/New_York" ||
        timezone == "America/Puerto_Rico" ||
        timezone == "America/Santiago"
      ) {
        i = -4;
      } else if (timezone == "America/Caracas") {
        // (GMT-04:30) 委内瑞拉时间 (America/Caracas)
        i = -4.5;
      } else if (
        timezone == "America/Bogota" ||
        timezone == "America/Chicago" ||
        timezone == "America/Lima" ||
        timezone == "America/Panama"
      ) {
        i = -5;
      } else if (
        timezone == "America/Denver" ||
        timezone == "America/El_Salvador" ||
        timezone == "America/Mexico_City"
      ) {
        i = -6;
      } else if (
        timezone == "America/Los_Angeles" ||
        timezone == "America/Phoenix" ||
        timezone == "America/Tijuana"
      ) {
        i = -7;
      } else if (timezone == "America/Anchorage") {
        // (GMT-08:00) 阿拉斯加夏令时 (America/Anchorage)
        i = -8;
      } else if (timezone == "Pacific/Honolulu") {
        i = -10;
      } else if (
        timezone == "Pacific/Niue" ||
        timezone == "Pacific/Pago_Pago"
      ) {
        i = -11;
      } else {
        i = 8;
      }
      if (country == "USA") {
        time = new Date(utcTime + 3600000 * i);
        (year = time.getFullYear()),
          (m = time.getMonth() + 1),
          (r = time.getDate()),
          (h = time.getHours()),
          (mm = time.getMinutes()),
          (s = time.getSeconds());
        var apm = "PM";
        var abm = "AM";
        /*从系统获取的时间为24小时制，我们减去12就可以转换成12小时制*/

        if (h > 12) {
          return (
            add0(m) +
            "/" +
            add0(r) +
            "/" +
            year +
            " " +
            add0(h) +
            ":" +
            add0(mm) +
            ":" +
            add0(s) +
            "  " +
            apm
          );
        } else {
          return (
            add0(m) +
            "/" +
            add0(r) +
            "/" +
            year +
            " " +
            add0(h) +
            ":" +
            add0(mm) +
            ":" +
            add0(s) +
            " " +
            abm
          );
        }
        // return year + '-' + add0(m) + '-' + add0(r) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
      } else {
        // i = 8
        time = new Date(utcTime + 3600000 * i);
        (year = time.getFullYear()),
          (m = time.getMonth() + 1),
          (r = time.getDate()),
          (h = time.getHours()),
          (mm = time.getMinutes()),
          (s = time.getSeconds());

        return (
          year +
          "-" +
          add0(m) +
          "-" +
          add0(r) +
          " " +
          add0(h) +
          ":" +
          add0(mm) +
          ":" +
          add0(s)
        );
      }
    },
    //显示
    showPanel() {
      this.panelShow = true;
    },
    confirmbtn() {
      this.isOk = false;
    },
    // 模态框
    showModal(item) {
      this.visible = true;
      this.emailItem = item;
    },
    //   取消删除同步邮件
    cancelbutton() {
      this.visible = false;
    },
    // 删除同步邮件
    handleDelete(val) {
      //
      this.listLoading = true;
      this.visible = false;
      let dataDel = {
        relateEmail: this.emailItem.syncmail,
        deleteType: val,
      };
      // 删除邮箱
      fetchData(
        window.Glod["ccex-emailsync"] + "/jwtdelsyncmail",
        { token: this.token, id: this.emailItem.id, cctype: "userid" },
        function (res) {
          let data = eval(res);
          if (data.code == 200) {
            this.$message.success(this.$i18n.t("label.tabpage.delsuccessz"));
            // 获取最新邮箱列表
            this.getemailbox();
            //  清空邮件
            //删除类型--0：清空所有  1：只清空未关联相关项的邮件
            deleteEmail(dataDel);
          } else {
            this.listLoading = false;
            this.isOk = true;
            this.emailListlen = 0;
            if (data.code == 401) {
              this.errorTitle = this.$i18n.t(
                "label_emailtocloudcc_redmessage7"
              );
              // this.errorTitle = "后台配置错误，请联系管理员"
            } else if (data.code == 403) {
              // this.errorTitle = "数据库错误，请联系管理员"
              this.errorTitle = this.$i18n.t(
                "label_emailtocloudcc_redmessage9"
              );
            } else if (data.code == 404) {
              // this.errorTitle = "信息已过期，请重新登录"
              this.errorTitle = this.$i18n.t("label_invalid_token");
            } else {
              if (!navigator.onLine) {
                this.errorTitle = this.$i18n.t(
                  "label_network_connection_failure"
                );
              } else {
                this.errorTitle = this.$i18n.t("label_server_is_time_out");
              }
            }
          }
        }.bind(this),
        function () {
          this.listLoading = false;
        }.bind(this)
      );
    },
    // 其他邮箱
    handleSubmit(e) {
      this.loading = true;
      e.preventDefault();
      this.$refs.form.validate((err) => {
        if (err) {
          fetchData(
            window.Glod["ccex-emailsync"] + "/jwtaddimapmail",
            {
              token: this.token,
              ccownerid: "0",
              cctype: 3,
              email: this.form.emailValue,
              password: this.form.passWordValue,
              imaphost: this.form.imapValue,
              imapport: this.form.imapNumValue,
              isimapssl: true,
              smtphost: this.form.smtpValue,
              smtpport: this.form.smtpNumValue,
              issmtpssl: true,
            },
            function (res) {
              let data = eval(res);

              if (data.code == 200) {
                this.emailListlen = true;
                this.panelShow = false;
                // 清空
                this.form = {
                  emailValue: "", // 邮箱号
                  passWordValue: "", // 邮箱密码
                  imapValue: "", // imap地址
                  imapNumValue: "993", // imap编号
                  smtpValue: "", // smtp地址
                  smtpNumValue: "465", // smtp编号
                }; // 其他邮箱
                if (this.ListOther) {
                  // 获取同步邮箱列表
                  fetchData(
                    window.Glod["ccex-emailsync"] + "/jwtgetsyncmails",
                    { token: this.token, cctype: "userid" },
                    function (res) {
                      let dataList = eval(res);
                      if (dataList.code == 200) {
                        this.emailListlen = dataList.jwtsyncmails.length;
                        this.emailList = dataList.jwtsyncmails;
                        // setTimeout(function(){
                        this.loading = false;
                        this.$emit("add", this.emailList);
                        this.$emit("listlen", this.emailListlen);
                        // this.$router.push({ path: `/?userid=${this.userid}&groupid=${this.groupid}&lange=${this.lange}&countryCode=${this.countrycode}`});
                        // window.location.reload()

                        // 清空
                        this.form = {
                          emailValue: "", // 邮箱号
                          passWordValue: "", // 邮箱密码
                          imapValue: "", // imap地址
                          imapNumValue: "993", // imap编号
                          smtpValue: "", // smtp地址
                          smtpNumValue: "465", // smtp编号
                        }; // 其他邮箱
                      } else {
                        this.isOk = true;
                        if (dataList.code == 401) {
                          this.errorTitle = this.$i18n.t(
                            "label_emailtocloudcc_redmessage7"
                          );
                          // this.errorTitle = "后台配置错误，请联系管理员"
                        } else if (dataList.code == 402) {
                          // this.errorTitle = '参数错误，请联系管理员'
                          this.errorTitle = this.$i18n.t(
                            "label_emailtocloudcc_redmessage8"
                          );
                        } else if (dataList.code == 403) {
                          // this.errorTitle = "数据库错误，请联系管理员"
                          this.errorTitle = this.$i18n.t(
                            "label_emailtocloudcc_redmessage9"
                          );
                        } else if (dataList.code == 404) {
                          // this.errorTitle = "信息已过期，请重新登录"
                          this.errorTitle = this.$i18n.t("label_invalid_token");
                        } else {
                          if (!navigator.onLine) {
                            this.errorTitle = this.$i18n.t(
                              "label_network_connection_failure"
                            );
                          } else {
                            this.errorTitle = this.$i18n.t(
                              "label_server_is_time_out"
                            );
                          }
                        }
                      }
                    }.bind(this)
                  );
                }
              } else {
                this.isOk = true;
                if (data.code == 100) {
                  this.errorTitle = this.$i18n.t(
                    "label_emailtocloudcc_redmessage7"
                  );
                } else if (data.code == 401) {
                  this.errorTitle = this.$i18n.t(
                    "label_emailtocloudcc_redmessage7"
                  );
                } else if (data.code == 402) {
                  this.errorTitle = this.$i18n.t(
                    "label_emailtocloudcc_redmessage8"
                  );
                } else if (data.code == 403) {
                  //   数据库错误，请联系管理员
                  this.errorTitle = this.$i18n.t(
                    "label_emailtocloudcc_redmessage9"
                  );
                } else if (data.code == 404) {
                  //   无效token
                  this.errorTitle = this.$i18n.t("label_invalid_token");
                } else if (data.code == 405) {
                  //   邮箱已存在
                  this.errorTitle = this.$i18n.t("label_emailtocloudcc_exists");
                } else if (data.code == 455) {
                  //   SMTP认证失败，请检查电子邮件地址与密码是否正确
                  this.errorTitle = this.$i18n.t(
                    "label_emailtocloudcc_redmessage6"
                  );
                } else if (data.code == 456) {
                  //   SMTP认证失败，请检查电子邮件地址与密码是否正确
                  this.errorTitle = this.$i18n.t(
                    "label_emailtocloudcc_redmessage11"
                  );
                } else if (data.code == 406) {
                  //   邮箱数量超过上限
                  this.errorTitle = this.$i18n.t("label_mailboxes_limit");
                } else if (data.code == 500) {
                  //   其它错误
                  this.errorTitle = this.$i18n.t("label_other_mistakes");
                }

                this.loading = false;
                // this.addEmail = `! ${data.codedesc}`
              }
            }.bind(this)
          );
        }
      });
    },
    // 关闭其他邮箱
    hidePanel() {
      // 下面的语句配合开头写的 .sync 就会更新父组件中的 panelShow 变量
      // this.$emit('update:panelShow', false)  //弹框隐藏，意为panelShow为false
      this.panelShow = false;
      // 清空
      this.form = {
        emailValue: "", // 邮箱号
        passWordValue: "", // 邮箱密码
        imapValue: "", // imap地址
        imapNumValue: "993", // imap编号
        smtpValue: "", // smtp地址
        smtpNumValue: "465", // smtp编号
      }; // 其他邮箱
    },
    adddata(emailList) {
      this.emailList = emailList;
      this.emailListlen = emailList.length;
    },
    // listlength(emailListlen){
    //   this.emailListlen = emailListlen
    // },

    // 添加gmail邮箱
    addGmail() {
      fetchData(
        window.Glod["ccex-emailsync"] + "/login/googlemail",
        {
          token: this.token,
          ccownerid: 0,
          cctype: 3,
          // "returnurl": `https://testcloudccmail.cloudcc.com/#/?userid=${this.userid}&groupid=${this.groupid}&lange=${this.lange}&countryCode=${this.countrycode}`,
          returnurl: `${this.returnurl}/#/?userid=${this.userid}&groupid=${this.groupid}&lange=${this.lange}&countryCode=${this.countrycode}`,
        },
        function (res) {
          let data = eval(res);
          if (data.code == 200) {
            window.location.href = data.redirecturl;
            this.loading = false;
          } else {
            this.isOk = true;
            if (data.code == 100) {
              this.errorTitle = this.$i18n.t(
                "label_emailtocloudcc_redmessage7"
              );
              // this.errorTitle = '状态未知，请联系管理员'
            } else if (data.code == 401) {
              this.errorTitle = this.$i18n.t(
                "label_emailtocloudcc_redmessage7"
              );
              // this.errorTitle = "后台配置错误，请联系管理员"
            } else if (data.code == 402) {
              // this.errorTitle = '参数错误，请联系管理员'
              this.errorTitle = this.$i18n.t(
                "label_emailtocloudcc_redmessage8"
              );
            } else if (data.code == 404) {
              this.errorTitle = this.$i18n.t("label_invalid_token");
            } else if (data.code == 405) {
              this.errorTitle = this.$i18n.t("label_emailtocloudcc_exists");
            } else if (data.code == 406) {
              this.errorTitle = this.$i18n.t("label_mailboxes_limit");
            } else if (data.code == 475) {
              this.errorTitle = this.$i18n.t("label_gmail_not_token");
            } else {
              if (!navigator.onLine) {
                this.errorTitle = this.$i18n.t(
                  "label_network_connection_failure"
                );
              } else {
                this.errorTitle = this.$i18n.t("label_server_is_time_out");
              }
            }
          }
        }.bind(this)
      );
    },
    chinaAddEmail() {
      this.isOk = true;
      // 中国大陆用户暂不支持此功能，感谢您的理解！
      this.errorTitle = this.$i18n.t("label_function_tip");
    },
    // 添加outlook邮箱
    addOutlook() {
      fetchData(
        window.Glod["ccex-emailsync"] + "/login/outlook",
        {
          token: this.token,
          // "token": this.token,
          ccownerid: 0,
          cctype: 3,
          // "returnurl": `https://testcloudccmail.cloudcc.com/#/?userid=${this.userid}&groupid=${this.groupid}&lange=${this.lange}&countryCode=${this.countrycode}`
          returnurl: `${this.returnurl}/#/?userid=${this.userid}&groupid=${this.groupid}&lange=${this.lange}&countryCode=${this.countrycode}`,
        },
        function (res) {
          let data = eval(res);
          if (data.code == 200) {
            window.location.href = data.redirecturl;
            this.loading = false;
          } else {
            this.isOk = true;
            if (data.code == 100) {
              this.errorTitle = this.$i18n.t(
                "label_emailtocloudcc_redmessage7"
              );
              // this.errorTitle = '状态未知，请联系管理员'
            } else if (data.code == 401) {
              this.errorTitle = this.$i18n.t(
                "label_emailtocloudcc_redmessage7"
              );
              // this.errorTitle = "后台配置错误，请联系管理员"
            } else if (data.code == 402) {
              // this.errorTitle = '参数错误，请联系管理员'
              this.errorTitle = this.$i18n.t(
                "label_emailtocloudcc_redmessage8"
              );
            } else if (data.code == 404) {
              this.errorTitle = this.$i18n.t("label_invalid_token");
            } else if (data.code == 405) {
              this.errorTitle = this.$i18n.t("label_emailtocloudcc_exists");
            } else if (data.code == 406) {
              this.errorTitle = this.$i18n.t("label_mailboxes_limit");
            } else {
              if (!navigator.onLine) {
                this.errorTitle = this.$i18n.t(
                  "label_network_connection_failure"
                );
              } else {
                this.errorTitle = this.$i18n.t("label_server_is_time_out");
              }
            }
          }
        }.bind(this)
      );
    },
    // 获取同步邮箱列表
    getemailbox() {
      fetchData(
        window.Glod["ccex-emailsync"] + "/jwtgetsyncmails",
        { token: this.token, cctype: "userid" },
        function (res) {
          let dataList = eval(res);
          if (dataList.code == 200) {
            this.emailList = dataList.jwtsyncmails;
            this.emailListlen = dataList.jwtsyncmails.length;
            this.listLoading = false;
          } else {
            this.listLoading = false;
            this.isOk = true;
            this.emailListlen = 0;
            if (dataList.code == 401) {
              this.errorTitle = this.$i18n.t(
                "label_emailtocloudcc_redmessage7"
              );
              // this.errorTitle = "后台配置错误，请联系管理员"
            } else if (dataList.code == 402) {
              // this.errorTitle = '参数错误，请联系管理员'
              this.errorTitle = this.$i18n.t(
                "label_emailtocloudcc_redmessage8"
              );
            } else if (dataList.code == 403) {
              // this.errorTitle = "数据库错误，请联系管理员"
              this.errorTitle = this.$i18n.t(
                "label_emailtocloudcc_redmessage9"
              );
            } else if (dataList.code == 405) {
              this.errorTitle = this.$i18n.t("label_emailtocloudcc_exists");
            } else if (dataList.code == 500) {
              // this.errorTitle = "其他錯誤"
              this.errorTitle = this.$i18n.t("label_other_mistakes");
            } else {
              if (!navigator.onLine) {
                this.errorTitle = this.$i18n.t(
                  "label_network_connection_failure"
                );
              } else {
                this.errorTitle = this.$i18n.t("label_server_is_time_out");
              }
            }
          }
        }.bind(this)
      );
    },
  },
  created() {
    // 测试url：personalEmailBox?userid=0052021B4C1837FyeQpK&groupid=orge46e336fa4d312eca&lange=zh&countryCode=CN&timezone=Asia%2FShanghai&rtnURL=http%3A%2F%2Flocalhost%3A9528%2F#/commonObjects/detail/008201913EB9D5FgCeoT/DETAIL
    // url传入的参数
    this.userid = this.$route.query.userid;
    this.groupid = this.$route.query.groupid;
    this.lange = this.$route.query.lange;
    // this.returnurl=this.$route.query.rtnURL
    this.timezone = this.$route.query.timezone;
    this.code = this.$route.query.code;
    this.countrycode = this.$route.query.countrycode;
    // 获取token
    this.listLoading = true;
    fetchData(
      window.Glod["ccex-emailsync"] + "/jwtgettoken",
      { userid: this.userid, groupid: this.groupid },
      function (res) {
        let data = eval(res);
        if (data.code == 200) {
          this.token = data.token;
          // 获取同步邮箱列表
          this.getemailbox();
        } else {
          this.listLoading = false;
          if (data.code == 401) {
            this.errorTitle = this.$i18n.t("label_emailtocloudcc_redmessage7");
            // this.errorTitle = "后台配置错误，请联系管理员"
          } else if (data.code == 402) {
            // this.errorTitle = '参数错误，请联系管理员'
            this.errorTitle = this.$i18n.t("label_emailtocloudcc_redmessage8");
          } else if (data.code == 403) {
            // this.errorTitle = "数据库错误，请联系管理员"
            this.errorTitle = this.$i18n.t("label_emailtocloudcc_redmessage9");
          } else if (data.code == 405) {
            this.errorTitle = this.$i18n.t("label_emailtocloudcc_exists");
          } else if (data.code == 500) {
            // this.errorTitle = "信息已过期，请重新登录"
            this.errorTitle = this.$i18n.t("label_invalid_token");
          } else {
            if (!navigator.onLine) {
              this.errorTitle = this.$i18n.t(
                "label_network_connection_failure"
              );
            } else {
              this.errorTitle = this.$i18n.t("label_server_is_time_out");
            }
          }
        }
      }.bind(this)
    );
  },
  mounted() {
    // 初始化邮箱列表

    if (this.code && this.code != 200) {
      this.isOk = true;
      if (this.code == 100) {
        //   状态未知，请联系管理员
        this.errorTitle = this.$i18n.t("label_emailtocloudcc_redmessage5");
        setTimeout(() => {
          this.isOk = false;
          // window.location.reload(`https://testcloudccmail.cloudcc.com/#/?userid=${this.userid}&groupid=${this.groupid}&lange=${this.lange}&countryCode=${this.countrycode}`)
          window.location.replace(
            `${this.returnurl}/#/?userid=${this.userid}&groupid=${this.groupid}&lange=${this.lange}&countryCode=${this.countrycode}`
          );
        }, 5000);
      } else if (this.code == 455 || this.code == 456) {
        //  SMTP认证失败，请检查电子邮件地址与密码是否正确
        this.errorTitle = this.$i18n.t("label_emailtocloudcc_redmessage6");
        setTimeout(() => {
          this.isOk = false;
          // window.location.reload(`https://testcloudccmail.cloudcc.com/#/?userid=${this.userid}&groupid=${this.groupid}&lange=${this.lange}&countryCode=${this.countrycode}`)
          window.location.replace(
            `${this.returnurl}/#/?userid=${this.userid}&groupid=${this.groupid}&lange=${this.lange}&countryCode=${this.countrycode}`
          );
        }, 5000);
      } else if (this.code == 401) {
        this.errorTitle = this.$i18n.t("label_emailtocloudcc_redmessage7");
        setTimeout(() => {
          this.isOk = false;
          // window.location.replace(`https://testcloudccmail.cloudcc.com/#/?userid=${this.userid}&groupid=${this.groupid}&lange=${this.lange}&countryCode=${this.countrycode}`)
          window.location.replace(
            `${this.returnurl}/#/?userid=${this.userid}&groupid=${this.groupid}&lange=${this.lange}&countryCode=${this.countrycode}`
          );
        }, 5000);
      } else if (this.code == 402) {
        this.errorTitle = this.$i18n.t("label_emailtocloudcc_redmessage8");
        setTimeout(() => {
          this.isOk = false;
          //  window.location.replace(`https://testcloudccmail.cloudcc.com/#/?userid=${this.userid}&groupid=${this.groupid}&lange=${this.lange}&countryCode=${this.countrycode}`)
          window.location.replace(
            `${this.returnurl}/#/?userid=${this.userid}&groupid=${this.groupid}&lange=${this.lange}&countryCode=${this.countrycode}`
          );
        }, 5000);
      } else if (this.code == 403) {
        this.errorTitle = this.$i18n.t("label_emailtocloudcc_redmessage9");
        setTimeout(() => {
          this.isOk = false;
          // window.location.replace(`https://testcloudccmail.cloudcc.com/#/?userid=${this.userid}&groupid=${this.groupid}&lange=${this.lange}&countryCode=${this.countrycode}`)
          window.location.replace(
            `${this.returnurl}/#/?userid=${this.userid}&groupid=${this.groupid}&lange=${this.lange}&countryCode=${this.countrycode}`
          );
        }, 5000);
      } else if (this.code == 404) {
        this.errorTitle = this.$i18n.t("label_emailtocloudcc_redmessage10");
        setTimeout(() => {
          this.isOk = false;
          // window.location.replace(`https://testcloudccmail.cloudcc.com/#/?userid=${this.userid}&groupid=${this.groupid}&lange=${this.lange}&countryCode=${this.countrycode}`)
          window.location.replace(
            `${this.returnurl}/#/?userid=${this.userid}&groupid=${this.groupid}&lange=${this.lange}&countryCode=${this.countrycode}`
          );
        }, 5000);
      } else if (this.code == 405) {
        this.errorTitle = this.$i18n.t("label_emailtocloudcc_exists");
        setTimeout(() => {
          this.isOk = false;
          //  window.location.replace(`https://testcloudccmail.cloudcc.com/#/?userid=${this.userid}&groupid=${this.groupid}&lange=${this.lange}&countryCode=${this.countrycode}`)
          window.location.replace(
            `${this.returnurl}/#/?userid=${this.userid}&groupid=${this.groupid}&lange=${this.lange}&countryCode=${this.countrycode}`
          );
        }, 5000);
      } else if (this.code == 406) {
        this.errorTitle = this.$i18n.t("label_mailboxes_limit");
        setTimeout(() => {
          this.isOk = false;
          //  window.location.replace(`https://testcloudccmail.cloudcc.com/#/?userid=${this.userid}&groupid=${this.groupid}&lange=${this.lange}&countryCode=${this.countrycode}`)
          window.location.replace(
            `${this.returnurl}/#/?userid=${this.userid}&groupid=${this.groupid}&lange=${this.lange}&countryCode=${this.countrycode}`
          );
        }, 5000);
      }
    }
  },
};
</script>

<style lang='scss' scoped>
::v-deep .el-input__inner {
  height: 36px;
  line-height: 36px;
}
::v-deep .el-form--label-top .el-form-item__label {
  padding: 0 0 0;
}
::v-deep .el-form-item {
  margin-bottom: 12px;
}
button:hover {
  cursor: pointer;
}
.cloudcc_email {
  margin-top: 10px;
  box-shadow: 0 0 8px 1px #d8dde6;
  border-radius: 4px;
  min-width: 907px;
  min-height: calc(100vh - 55px - 42px - 61px - 20px - 20px - 10px);
  width: 100%;
  background: #ffffff;
}
.cloudcc_title {
  width: 100%;
  padding: 10px 20px;
  display: flex;
  background: #fafafa;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #ededed;
}
.cloudcc_title h3 {
  font-size: 16px;
  color: #333;
}
.cloudcc_title p {
  font-size: 12px;
  color: #333;
  line-height: 24px;
}
.title_img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.h3Class {
  padding: 0;
  margin: 0;
  height: 40px;
  line-height: 40px;
}
/* 无数据样式 */
.init_email {
  padding: 80px 0 40px;
  display: flex;
  justify-content: center;
  max-width: 600px;
  flex-wrap: wrap;
  margin: 0 auto;
}
.init_email li {
  width: 180px;
  height: 180px;
  border: 2px solid #d8dde6;
  border-radius: 10px;
  position: relative;
  margin-right: 110px;
  margin-bottom: 50px;
  flex-shrink: 0;
}
.init_email li:nth-of-type(2n) {
  margin-right: 0;
}
.init_email_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.init_email_icon:hover {
  cursor: pointer;
}
.init_email_icon .img {
  display: inline-block;
  width: 70px;
  height: 70px;
  margin-bottom: 22px;
}
.init_email_icon span {
  font-weight: bold;
}
.init_email li:nth-of-type(1) .init_email_icon span {
  color: #d54b3d;
}
.init_email li:nth-of-type(2) .init_email_icon span {
  color: #dd3f13;
}
.init_email li:nth-of-type(3) .init_email_icon span {
  color: #0072c6;
}
.init_email li:nth-of-type(4) .init_email_icon span {
  color: #66b1ff;
}
.init_email li:nth-of-type(1) .init_email_icon img {
  width: 70px;
}
.init_email li:nth-of-type(2) .init_email_icon img {
  width: 50px;
}
.init_email li:nth-of-type(3) .init_email_icon img {
  width: 60px;
}
.init_email li:nth-of-type(4) .init_email_icon img {
  width: 56px;
}
.init_email li:hover {
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.28);
  border: 2px solid #66b1ff;
}
/* --- */
body {
  position: relative;
}
.init_email_transit {
  height: 30px;
  background: #f8f8f8;
}
.email_list {
  background: #ffffff;
  border-radius: 0 0 4px 4px;
  padding: 15px 20px;
  min-height: 320px;
  width: 100%;
}
.init_email_content {
  text-align: right;
  padding: 18px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.init_email_content span {
  display: block;
  cursor: pointer;
}
.init_email_content span {
  margin-left: 40px;
}
.init_email_content span:nth-of-type(1) img {
  width: 36px;
}
.init_email_content span:nth-of-type(2) img {
  width: 36px;
}
.init_email_content span:nth-of-type(3) img {
  width: 30px;
}
.init_email_content span:nth-of-type(4) img {
  width: 30px;
}
.init_email_content span:nth-of-type(5) img {
  width: 30px;
}
.init_email_content span:nth-of-type(6) img {
  width: 30px;
}
.init_email_content span:nth-of-type(7) img {
  width: 30px;
}

.email_list table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  position: relative;
}
.email_list table tr {
  height: 37px;
  border-top: 1px solid #dcdcdc;
}
.email_list table tbody tr:nth-of-type(2n + 1) {
  background: #f3f6f9;
}
.email_list table tr:last-child {
  border-bottom: 1px solid #dcdcdc;
}
.email_list table tr th {
  width: 25%;
  padding: 0 20px;
  text-align: left;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #333333;
  letter-spacing: 0;
  line-height: 36px;
}
.email_list_th {
  display: flex;
  align-items: center;
}
.email_list_th span:nth-of-type(1) {
  display: block;
  height: 14px;
  width: 2px;
  background: #dcdcdc;
  margin-right: 10px;
}
.email_list_th span:nth-of-type(2) {
  display: block;
}
.email_list table tr td {
  padding: 0 30px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #333333;
  letter-spacing: 0;
  line-height: 36px;
}
.email_list table tr .edit_td .line_icon {
  margin: 0 3px;
  vertical-align: top;
  height: 20px;
}
.delete_btn {
  background: none;
  display: inline;
  border: none;
  color: #32748e;
}
.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99;
}
.modal .modal_box {
  height: 187px;
  width: 440px;
  background: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  margin: 150px auto;
  padding: 20px;
  box-sizing: border-box;
}
.modal .modal_box .modal_title {
  padding-bottom: 10px;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  justify-content: space-between;
}
.modal .modal_box .modal_title h4 {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
}
.modal .modal_box .modal_detail {
  text-align: center;
}
.modal .modal_box .modal_detail p {
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #777777;
  letter-spacing: 0;
  margin-top: 20px;
  text-align: left;
}
.modal .confirm_btn {
  background: #66b1ff;
  border: 1px solid #66b1ff;
  border-radius: 4px;
  padding: 8px 20px;
  box-sizing: border-box;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  margin: 36px auto;
}
.loading_List {
  width: 94%;
  background: rgba(255, 255, 255, 0.7);
  text-align: center;
  z-index: 666;
  position: absolute;
}
.errorBox {
  background: rgba(16, 16, 16, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1111;
}
.error {
  width: 500px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  text-align: center;
  padding: 30px;
}
.error_title {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}
.error span {
  display: block;
  width: 100%;
  text-align: left;
}
.error_icon {
  color: #f5222d;
  font-size: 20px;
  font-weight: bold;
  margin-right: 8px;
}
.errorBox button {
  padding: 6px 18px;
  border: none;
  background: #66b1ff;
  color: #fff;
  border-radius: 4px;
}

.spin-content {
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
  padding: 30px;
}
.email_Other {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 666;
}
.email_Other_box {
  width: 440px;
  height: 568px;
  background: #fff;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
}
.email_other_close {
  border-bottom: 1px solid #dcdcdc;
  text-align: right;
  padding: 6px;
  box-sizing: border-box;
}
.closeSvg {
  display: inline-block;
  width: 26px;
  height: 26px;
}
.email_other_logo {
  text-align: center;
  // padding-top: 10px;
  .img3 {
    height: 80px;
    width: 126px;
  }
}
.email_other_H3 {
  text-align: center;
  font-size: 16px;
}
.email_other_login {
  padding: 0 70px;
  margin-top: -15px;
}
.input_box {
  margin-bottom: 10px;
}

#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
  background: #66b1ff;
  border: none;
}
#components-form-demo-normal-login .login-form-button span {
  color: #fff;
}
.login-form label {
  font-size: 12px;
}
.email_other_Set {
  font-size: 16px;
  color: #333333;
  text-align: center;
  /* padding-top: 20px; */
  margin-bottom: 10px;
}
.email_other_host {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.email_other_box {
  display: flex;
  flex-direction: column;
}
.email_other_box label {
  font-size: 14px;
  color: #777777;
  letter-spacing: 0;
}
.email_other_host_input {
  width: 216px;
}
.email_other_host_num {
  width: 73px;
}
.email_other_host:nth-of-type(2) {
  margin-bottom: 35px;
}
.email_loading {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
}
.email_loading img {
  width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.error_add {
  padding: 0 70px;
  color: #f50;
}

.errorBox {
  background: rgba(16, 16, 16, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1111;
}
.error {
  width: 500px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  text-align: center;
  padding: 30px;
}
.error_title {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}
.error span {
  display: block;
  width: 100%;
  text-align: left;
}
.error_icon {
  color: #f5222d;
  font-size: 20px;
  font-weight: bold;
  margin-right: 8px;
}
.errorBox button {
  background: #66b1ff !important;
  border: 1px solid #66b1ff !important;
  border-radius: 4px;
  color: #fff;
  padding: 4px 20px;
}
/* 返回上一页按钮 */
.btn_box {
  margin-top: 20px;
  text-align: right;
}
.btn_box a {
  font-size: 14px;
}
</style>
